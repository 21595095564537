<template>
  <v-container id="profilePhotoContainer" fluid>
    <v-snackbar v-model="showSnackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-row align="center" justify="center">
      <!-- Display Profile Photo or icon -->
      <v-col
        cols="12"
        sm="12"
        md="5"
        class="d-flex justify-center justify-md-end"
      >
        <div>
          <!-- Profile Photo -->
          <div>
            <v-avatar width="150" height="150" v-if="userData.photoURL">
              <v-img class="d-block mx-auto" :src="userData.photoURL" />
            </v-avatar>
            <v-icon v-else size="150">mdi-account-circle</v-icon>
          </div>

          <!-- Edit Profile Photo Button Dialog-->
          <v-dialog
            v-model="dialog"
            width="500"
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                :disabled="loading || superLoading"
                class="mt-4 ml-10"
                color="deep-purple"
                v-on="on"
                small
                outlined
              >
                <v-icon class="mr-1" small>mdi-camera-account</v-icon> Edit
              </v-btn>
            </template>

            <v-card>
              <v-toolbar dark color="blue" dense>
                <v-btn
                  icon
                  dark
                  :disabled="loading || superLoading"
                  @click="dialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Change Profile Photo</v-toolbar-title>
              </v-toolbar>

              <UploadForm
                :uid="userData.uid"
                @success="showMessage(true)"
                @failed="showMessage(false)"
              />
            </v-card>
          </v-dialog>
        </div>
      </v-col>

      <!-- Name and Sub Status -->
      <v-col
        cols="12"
        sm="12"
        md="7"
        class="d-flex justify-center justify-md-start"
      >
        <!-- User Name and Sub Status -->
        <v-list-item class="mt-md-n6" two-line>
          <v-list-item-content>
            <v-list-item-title
              class="
                d-flex
                align-center
                justify-center justify-md-start
                text-h5
              "
            >
              {{ userData.fullName }}
              <v-icon
                v-if="isUserSubscribed"
                class="ml-1"
                color="amber darken-2"
                >mdi-crown-outline</v-icon
              >
            </v-list-item-title>
            <v-list-item-subtitle class="mt-3">
              <v-alert
                v-if="isUserSubscribed"
                color="amber darken-2"
                max-width="250"
                dense
                text
              >
                Subscribed Till : {{ subscribedTillDateText }}
              </v-alert>
              <span v-else>
                <v-btn
                  color="amber darken-2 white--text"
                  :disabled="loading || superLoading"
                  to="/premium-pass"
                >
                  <v-icon class="mr-2"> mdi-crown-outline</v-icon>
                  Unlock All Tests
                </v-btn>
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UploadForm from "@/components/Home/Profile/ProfilePhotoContainer/UploadForm.vue";

export default {
  name: "ProfilePhotoContainer",
  props: [
    "superLoading",
    "userData",
    "isUserSubscribed",
    "subscribedTillDateText",
  ],
  components: {
    UploadForm,
  },
  data: () => ({
    loading: false,
    dialog: false,
    showSnackbar: false,
    snackbarText: "",
  }),
  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },
    showMessage(value) {
      if (value) {
        this.snackbarText = "Profile photo updated successfully :)";
      } else {
        this.snackbarText = "Error in uploading photo, please try again :(";
      }
      this.dialog = false;
      this.showSnackbar = true;
    },
  },
};
</script>


