<template>
  <v-container id="profileDetailsContainer">
    <!-- Snackbar for notifications -->
    <v-snackbar v-model="showSnackbar">
      {{ snackbarText }}
    </v-snackbar>

    <!-- Form Row -->
    <v-row justify="center">
      <!-- heading -->
      <v-col cols="12" sm="12" class="d-flex mt-4 justify-center">
        <v-icon class="mr-2" color="blue darken-2">mdi-account-edit</v-icon
        >Personal Details :
      </v-col>

      <v-form
        v-model="valid"
        @submit.prevent="submit"
        ref="personalDetailsForm"
      >
        <v-container>
          <v-row v-if="error">
            <v-col cols="12">
              <v-alert type="error">{{ error }}</v-alert>
            </v-col>
          </v-row>
          <v-row v-if="courses.length > 0">
            <v-col cols="12" sm="12">
              <!-- Full Name -->
              <v-text-field
                color="deep-purple accent-4"
                v-model="fullName"
                type="text"
                :rules="fullNameRules"
                label="Full Name"
                :disabled="loading || superLoading"
                filled
                rounded
                required
              ></v-text-field>

              <!-- Phone -->
              <v-text-field
                prefix="+91 "
                color="deep-purple accent-4"
                v-model="phone"
                maxlength="10"
                type="number"
                :rules="phoneRules"
                label="Phone Number"
                :disabled="loading || superLoading"
                filled
                rounded
                required
              ></v-text-field>

              <!-- Selected Course -->
              <v-select
                v-model="selectedCourse"
                :items="courses"
                item-text="courseName"
                :rules="fullNameRules"
                label="Select Course"
                :disabled="loading || superLoading || !valid"
                filled
                rounded
                required
              ></v-select>
            </v-col>

            <!-- Submit Button -->
            <v-col cols="12" class="d-flex justify-end">
              <v-btn
                color="blue darken-2 white--text"
                :disabled="loading || superLoading || !valid"
                raised
                rounded
                @click="submit"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ProfileDetailsContainer",
  props: ["superLoading", "userData"],
  data: () => ({
    loading: false,
    showSnackbar: false,
    snackbarText: "",
    error: "",
    courses: [],
    valid: false,

    // Values
    fullName: "",
    phone: "",
    selectedCourse: "",

    // Rules
    fullNameRules: [
      (value) => !!value || "Required.",
      (value) => value.length >= 3 || "Minimum 3 charachters required.",
    ],
    phoneRules: [
      (value) => !!value || "Required.",
      (value) => value.length == 10 || "Please provide a valid phone number",
      (value) =>
        /^[6-9]\d{9}$/.test(value) || "Please provide a valid phone number",
    ],
  }),
  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },

    submit() {
      this.setLoading(true);
      this.error = "";

      if (!this.$refs.personalDetailsForm.validate()) {
        return;
      }

      const payload = {
        fullName: this.fullName,
        phone: parseInt(this.phone),
        selectedCourse: this.selectedCourse,
      };

      this.$store
        .dispatch("submitProfile", payload)
        .then(() => {
          this.snackbarText = "Profile Updated Successfully :)";
        })
        .catch((err) => {
          this.snackbarText = "Error in updating profile, please try again :(";
          this.error = err;
        })
        .finally(() => {
          this.showSnackbar = true;
          this.setLoading(false);
        });
    },

    fetchCourses() {
      // get courses array from store
      const coursesArr = this.$store.getters.courses;

      //if not found in store
      if (coursesArr.length <= 0 || typeof coursesArr == "undefined") {
        //get folder names from server
        this.$store
          .dispatch("getMetaDoc")
          .then((res) => {
            if (res) {
              this.courses = res.courses;
            }
          })
          .catch((error) => {
            this.error = error;
          })
          .finally(() => {
            this.setLoading(false);
          });
      } else {
        // if found in store
        this.courses = coursesArr;
        this.setLoading(false);
      }
    },
  },
  mounted() {
    this.fetchCourses();
    this.fullName = this.userData.fullName;
    this.phone = `${this.userData.phone}`;
    this.selectedCourse = this.userData.selectedCourse;
  },
};
</script>


